import PersonalCenter from "@/views/personal-center/index.vue";
export default {
  path: "/personal-center",
  component: PersonalCenter,
  name: "PersonalCenter",
  children: [
    {
      path: "userinfo",
      name: "userinfo",
      component: () => import("@/views/personal-center/userinfo.vue"),
    },
    {
      path: "my-collection",
      component: () => import("@/views/personal-center/my-collection.vue"),
    },
    {
      path: "my-download",
      component: () => import("@/views/personal-center/my-download.vue"),
    },
    {
      path: "key-manage",
      component: () => import("@/views/personal-center/key-manage.vue"),
    },
    {
      path: "order-form",
      component: () => import("@/views/personal-center/order-form.vue"),
    },
  ],
};
