// 手机号验证
export function validMobile(str) {
  return /^1[3-9]\d{9}$/.test(str);
}
// 邮箱验证
export function validMailbox(str) {
  return /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(
    str
  );
}
// 微信验证
export function validWechat(str) {
  return /^[a-zA-Z][a-zA-Z\d_-]{5,19}$/.test(str);
}
