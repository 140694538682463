import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/intercept";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./styles/index.scss";
import i18n from "@/lang";
import * as filters from "@/filters";
import VideoPlayer from "vue-video-player";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
Vue.use(VideoPlayer);
// 注册全局的过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
