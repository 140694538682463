<template>
  <div class="help-center">
    <div class="container">
      <el-menu
        class="el-menu-vertical-demo"
        background-color="#F8F8F8"
        text-color="#000"
        active-text-color="#ffd04b"
        :default-active="$route.path"
        router
      >
        <div class="title">{{ $t("help.title") }}</div>
        <el-menu-item index="/help-center">
          <span slot="title">{{ $t("help.router1") }}</span>
        </el-menu-item>
        <el-menu-item index="/help-center/picture-teaching/ue">
          <span slot="title">{{ $t("help.router2") }}</span>
        </el-menu-item>
        <el-menu-item index="/help-center/video-teaching">
          <span slot="title">{{ $t("help.router3") }}</span>
        </el-menu-item>
      </el-menu>
      <div class="router-container">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpCenter",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.help-center {
  background-image: linear-gradient(to right, #6e2934, #2d0f23);
  overflow: hidden;
  .container {
    position: relative;
    margin: 140px auto 60px;
    width: 1441px;
    height: 938px;
    background-color: #fff;
    overflow: hidden;
    border-radius: 10px;
    .el-menu-vertical-demo {
      text-align: center;
      width: 200px;
      height: 100%;
      border-right: none;
      .title {
        text-align: center;
        line-height: 106px;
        font-size: 20px;
        font-weight: bold;
        color: #1f1f1f;
      }
      .el-menu-item {
        font-size: 16px;
      }
      .is-active {
        position: relative;
        background-color: #fff !important;
      }
      .is-active::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 5px;
        height: 30px;
        background-color: #ffc322;
      }
    }
    .router-container {
      position: absolute;
      left: 200px;
      top: 0;
      width: 1256px;
      height: 100%;
   overflow: auto;

      box-sizing: border-box;
    }
  }
}
</style>
