import request from "@/utils/request";
/**
 * 获取验证码
 */
export const loginCode = (data) => {
  return request({
    url: "users/login-by-code/code",
    method: "POST",
    data,
  });
};
/**
 * 登录
 */
export const login = (data) => {
  return request({
    url: "users/login-by-code",
    method: "POST",
    data,
  });
};
/**
 * token过期
 */
export const tokenExpired = (data) => {
  return request({
    url: "/users/refreshtoken",
    method: "POST",
    data,
  });
};
