import Vue from "vue";
import Vuex from "vuex";
import { getItem, setItem, removeItem } from "@/utils/storage";
import { uuid } from "vue-uuid"; // 唯一标识符号
// import { userInfo } from '@/api/user'
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    uuid: getItem("uuid"),
    token: getItem("token"),
    phone: "",
  },
  mutations: {
    // 生成uuid
    setuuid() {
      setItem("uuid", uuid.v4());
    },
    setPhone(state, payload) {
      state.phone = payload;
    },
    // 获取token
    setToken(state, payload) {
      setItem("token", payload);
      state.token = payload;
    },
    removeToken(state) {
      removeItem("token");
      state.token = null;
    },
  },
  actions: {},
  getters: {
    token: (state) => state.token && state.token.token,
  },
});
