<template>
  <div class="language">
    <el-button
      type="text"
      v-if="$i18n.locale === 'en'"
      @click="changeLanguage('zh')"
      icon="iconfont icon-zhongwen"
    >
    </el-button>
    <el-button
      type="text"
      v-else
      @click="changeLanguage('en')"
      icon="iconfont icon-yingwen"
    >
    </el-button>
  </div>
</template>

<script>
export default {
  methods: {
    changeLanguage(type) {
      sessionStorage.setItem("language", type);
      this.$i18n.locale = type;
      if (type === 'en') {
        document.querySelector('#companyName').innerHTML = 'Prometheus' 
      } else {
        document.querySelector('#companyName').innerHTML = '普罗米修斯视觉' 
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.language {
  //  float: right;
  ::v-deep.el-button {
    margin-left: 20px;
    color: #fff;
    .iconfont {
      font-size: 25px;
      line-height: 36px;
    }
  }
}
</style>
