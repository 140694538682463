import HelpCenter from "@/views/help-center";
export default {
  path: "/help-center",
  component: HelpCenter,
  redirect: "/help-center/using-boot",
  name: "HelpCenter",
  children: [
    {
      path: "",
      component: () => import("@/views/help-center/using-boot.vue"),
    },
    {
      path: "picture-teaching",
      component: () => import("@/views/help-center/picture-teaching.vue"),
      // redirect: 'picture-teaching/ue',
      children:[
        {
          path: "ue",
          name: 'UE',
          component: () => import("@/views/help-center/descriptionDocument/ue.vue"),
        },
        {
          path: 'unity',
          name: 'Unity',
          component: ()=> import("@/views/help-center/descriptionDocument/unity.vue")
        }
      ]
    },
    {
      path: "video-teaching",
      component: () => import("@/views/help-center/video-teaching.vue"),
    },
  ],
};
