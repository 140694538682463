<template>
  <el-dialog
    class="loginDialog"
    width="34%"
    :visible="dialogLogin"
    :modal="false"
    :center="true"
    @close="cancel"
  >
    <template slot="title">
      <div class="title" />
    </template>
    <el-form
      ref="loginForm"
      class="container"
      style="padding: 0 3.125rem"
      :model="loginForm"
      :rules="$i18n.locale === 'en' ? loginRules1 : loginRules"
    >
      <div class="formTitle">{{ $t("login.register") }}</div>
      <el-form-item prop="phone">
        <el-input
          v-model="loginForm.phone"
          :placeholder="$t('login.phone')"
          autocomplete="off"
          prefix-icon="iconfont icon-shouji"
        />
      </el-form-item>
      <el-form-item class="code-input" prop="code">
        <el-row>
          <el-col :span="17">
            <el-input
              v-model="loginForm.code"
              :placeholder="$t('login.code')"
              autocomplete="off"
              prefix-icon="iconfont icon-24gl-shieldCheck"
            />
          </el-col>
          <el-col :span="7">
            <el-button
              class="code-btn"
              @click="getCode"
              v-loading="codeload"
              :disabled="disabled"
              >{{
                disabled
                  ? $i18n.locale === "zh"
                    ? `${count}秒后重新发送`
                    : `Resend in ${count}s`
                  : $t("login.verification")
              }}</el-button
            >
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <!-- <el-checkbox-group v-model="needlessLogin" class="needlessLogin">
      <el-checkbox label="15天免登录" name="type" />
    </el-checkbox-group>-->
    <div slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        class="loginBtn btnColor"
        @click="login"
        v-loading="loginload"
        id="loginAndRegister"
        >{{ $t("login.login") }}</el-button
      >
      <div class="agreement">
        <!-- 注册或点击登录代表您同意
        <span>《普罗米修斯网络服务使用协议》</span>
        <span>《普罗米修斯隐私政策》</span> -->
        {{ $t("login.agreement") }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { validMobile } from "@/utils/validate";
import { loginCode, login } from "@/api/login";
import { toggle, toggleError } from "@/utils/common";
export default {
  name: "Login",
  props: {
    dialogLogin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const validateMobile = (rule, value, callback) => {
      if (!validMobile(value)) {
        if (this.$i18n.locale === "zh") {
          callback(new Error("手机号格式不正确"));
        } else {
          callback(new Error("Incorrect phone number"));
        }
      } else {
        callback();
      }
    };
    return {
      count: 60,
      disabled: false,
      loginload: false,
      codeload: false,
      timer: null,
      loginForm: {
        phone: "",
        code: "",
      },
      // needlessLogin: false, // 15天免登录
      loginRules: {
        phone: [
          { required: true, trigger: "blur", message: "手机号不能为空" },
          {
            validator: validateMobile,
            trigger: "blur",
          },
        ],
        code: [
          { required: true, trigger: "blur", message: "验证码不能为空" },
          {
            trigger: "blur",
            min: 6,
            max: 6,
            message: "请输入6位数验证码",
          },
        ],
      }, // 表单验证
      loginRules1: {
        phone: [
          {
            required: true,
            trigger: "blur",
            message: "The phone number is required",
          },
          {
            validator: validateMobile,
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            trigger: "blur",
            message: "The verification code is required",
          },
          {
            trigger: "blur",
            min: 6,
            max: 6,
            message: "Please fill in the 6-digit verification code",
          },
        ],
      }, // 英文
    };
  },
  methods: {
    // 请求验证码
    getCode() {
      this.codeload = true;
      this.$refs.loginForm.validateField("phone", async (callback) => {
        if (!callback) {
          await loginCode(this.loginForm);
          this.codeload = false;
          toggle("获取验证码成功", "Verification Code Acquired");
          this.disabled = true;
          this.countDown();
        } else {
          this.codeload = false;
          // this.$message.error("手机号格式不正确");
        }
      });
    },
    // 倒计时
    countDown() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.count--;
        if (this.count == -1) {
          clearInterval(this.timer);
          this.disabled = false;
          this.count = 60;
        }
      }, 1000);
    },
    // 关闭登录窗口
    cancel() {
      this.$emit("login", false);
      this.$refs.loginForm.resetFields();
    },
    // 登录
    login() {
      this.loginload = true;
      this.$refs.loginForm
        .validate()
        .then(async () => {
          const { data } = await login(this.loginForm);
          this.$store.commit("setToken", data);
          toggle("登录成功", "login successfully");
          this.$emit("login", false);
          this.$router.go();
          this.loginload = false;
        })
        .catch((err) => {
          toggleError(
            "验证码或手机号格式不正确",
            "Incorrect verification code/phone number"
          );
          this.loginload = false;
          return Promise.resolve(err);
        });
    },
  },
};
</script>
<style lang="scss">
.loginDialog {
  .el-dialog {
    border-radius: 0.625rem;
    overflow: hidden;
    .el-dialog__header {
      background-color: #fafafa;
      padding: 0.625rem 1.25rem;
      .title {
        margin: 0 auto;
        width: 11.4375rem;
        height: 2.875rem;
        background: url("~@/assets/logo.png") no-repeat;
        background-size: 140% 100%;
      }
      .el-dialog__headerbtn {
        font-size: 1.375rem;
      }
    }
    .el-dialog__body {
      padding: 0;
      .container {
        .formTitle {
          width: 100%;
          color: #000;
          text-align: center;
          font-size: 1.25rem;
          margin: 1.875rem auto;
        }
      }

      .el-input__inner:focus {
        outline: none;
        border: 1px solid #a944ff;
      }
      .code-input {
        .code-btn {
          // padding: 0;
          background-color: #ffc322;
          color: #fff;
          margin-left: 6.5%;
          border: none;
          width: 93.5%;
        }
      }
    }
    .el-dialog__footer {
      padding: 0;
      padding-bottom: 30px;
      .dialog-footer {
        .loginBtn {
          width: 90%;
          background-color: #ffc322;
          color: #fff;
          border: none;
          margin-bottom: 30px;
        }
        .agreement {
          font-size: 14px;

          //   span:hover {
          //     color: #ffc322;
          //   }
        }
      }
    }

    .needlessLogin {
      margin: 1.25rem 0 1.25rem 3.125rem;
      .el-checkbox__inner {
        width: 1.25rem;
        height: 1.25rem;
      }
      .el-checkbox__inner:hover {
        border: 0.0625rem solid #dcdfe6;
      }
      .el-checkbox__inner::after {
        content: "";
        position: absolute;
        top: 0.125rem;
        left: 0.375rem;
        height: 0.5625rem;
        width: 0.25rem;
        border: 0.125rem solid #a944ff;
        border-left: 0;
        border-top: 0;
      }
      .el-checkbox__label {
        color: #9a9a9a;
      }
      .is-focus {
        .el-checkbox__inner {
          border-color: #dcdfe6;
        }
      }
      .is-checked {
        .el-checkbox__inner {
          background-color: #fff;
          border-color: #a944ff;
        }
      }
    }
  }
}
</style>
