<template>
  <div class="nav" ref="nav">
    <div class="container">
      <div class="titleImg" @click="$router.push('/')" />
      <el-menu
        :default-active="$route.path"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#00000000"
        text-color="#fff"
        active-text-color="#FFC322"
        router
      >
        <el-menu-item index="/">{{ $t("navbar.material") }}</el-menu-item>
        <el-menu-item v-if="language === 'zh-CN'" index="/plug-in">{{ $t("navbar.plug-in") }}</el-menu-item>
        <el-menu-item v-else index="/plug-in-en">Plug-ins</el-menu-item>
        <!-- <el-menu-item index="/plug-in-en">Plug-ins</el-menu-item> -->
        <!-- <el-menu-item index="/halo-studio">Halo-Studio</el-menu-item> -->
        <!-- <el-menu-item index="/objToMp4">OBJ转MP4</el-menu-item> -->
        <el-menu-item index="/help-center">{{
          $t("navbar.help")
        }}</el-menu-item>
        <!-- <el-menu-item index="/aboutUs">{{ $t("navbar.about") }}</el-menu-item> -->
        <lang />
      </el-menu>
      <div v-if="!loginSuccess" class="userInfo" @click.stop="login(true)">
        <el-avatar class="avatar" icon="el-icon-user-solid" size="small" />
        <el-button class="login" type="text">{{ $t("login.login") }}</el-button>
      </div>
      <div v-else class="logged">
        <div @click.stop="loggedShow = !loggedShow">
          <img
            src="@/assets/geren.png"
            class="avatarImg"
            width="50px"
            height="50px"
          />
        </div>
        <div v-show="loggedShow" class="loggedShow">
          <div class="message" @click.stop>
            <img
              src="@/assets/geren.png"
              class="avatarImg"
              width="50px"
              height="50px"
            />
            <span class="names">{{ phone }}</span>
          </div>
          <div class="user-btn">
            <el-menu router @select="centerSelect">
              <el-menu-item index="/personal-center/userinfo">
                <i class="iconfont icon-gerenzhongxin" />
                <span>{{ $t("login.message") }}</span>
              </el-menu-item>
              <el-menu-item index="/personal-center/my-download">
                <i class="iconfont icon-wodexiazai" />
                <span slot="title">{{ $t("login.download") }}</span>
              </el-menu-item>
              <el-menu-item index="/personal-center/my-collection">
                <i class="iconfont icon-wodeshoucang" />
                <span slot="title">{{ $t("login.collect") }}</span>
              </el-menu-item>
              <el-menu-item index="/personal-center/key-manage">
                <i class="iconfont icon-yuechi" />
                <span slot="title">{{ $t("login.key") }}</span>
              </el-menu-item>
              <!-- <el-menu-item index="/personal-center/order-form">
                <i class="iconfont icon-dingdan" />
                <span slot="title">{{$t("login.dingdan")}}</span>
              </el-menu-item> -->
              <el-menu-item @click="logout">
                <i class="iconfont icon-tuichudenglu" />
                <span slot="title">{{ $t("login.out") }}</span>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </div>
    </div>
    <login :dialog-login="dialogLogin" @login="login" />
  </div>
</template>
<script>
import login from "./login";
import { userInfo } from "@/api/user";
import lang from "./lang";
export default {
  name: "",
  components: {
    login,
    lang,
  },
  data() {
    return {
      dialogLogin: false,
      loggedShow: false,
      phone: "",
      language: 'zh-CN'
    };
  },
  computed: {
    // 登录成功
    loginSuccess() {
      return !!this.$store.state.token;
    },
  },
  created() {
    this.language = navigator.language
  },
  mounted() {
    
    if (!sessionStorage.getItem('language')) {
      sessionStorage.setItem('language','zh')
    } 
    //点击任何地方隐藏下拉框
    document.body.addEventListener("click", (e) => {
      this.loggedShow = false;
    });
    if (this.$store.state.token) this.userInfo();
    this.windowScroll();
  },
  methods: {
    //获取用户信息
    async userInfo() {
      const { data } = await userInfo();
      this.phone = data.phone;
      this.$store.commit("setPhone", data.phone);
    },
    //隐藏下拉框
    centerSelect() {
      this.loggedShow = false;
    },
    //登录窗口
    login(boolean) {
      this.dialogLogin = boolean;
    },
    //监听页面滚动事件
    windowScroll() {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset <= 200) {
          this.$nextTick(() => {
            try {
              this.$refs.nav.style.backgroundColor = "#00000050";
            } catch (err) {
              return Promise.resolve(err);
            }
          });
        } else {
          this.$nextTick(() => {
            try {
              this.$refs.nav.style.backgroundColor = "#000000";
            } catch (err) {
              return Promise.resolve(err);
            }
          });
        }
      });
    },
    // 退出登录
    logout() {
      this.$store.commit("removeToken");
      this.$router.go();
    },
  },
};
</script>
<style lang="scss" scoped>
.nav {
  height: 70px;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  .container {
    position: relative;
    margin: 0 auto;
    height: 100%;
    width: 1380px;
    .titleImg {
      float: left;
      width: 211px;
      height: 42px;
      margin: 14px 77px 14px 0;
      cursor: pointer;
      background-image: url("~@/assets/logo1.png");
    }
    .el-menu.el-menu--horizontal {
      display: inline-block;
      min-width: 600px;
      height: 100%;
      padding: 5px 0;
      border-bottom: none;
      overflow: hidden;
      box-sizing: border-box;
      background-color: transparent !important;
      .el-menu-item {
        background-color: transparent !important;
      }
      .homepage {
        color: #fff;
        line-height: 60px;
        font-size: 14px;
        padding: 0 20px;
      }
      .is-active {
        color: #fff !important;
        font-weight: bold;
        background-color: transparent;
      }
    }
    .logged {
      float: right;
      margin-top: 10px;
      margin-right: 30px;
      .loggedShow {
        position: absolute;
        top: 70px;
        right: 0;
        width: 280px;
        // height: 329px;
        background: #ffffff;
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
        border-radius: 10px;
        z-index: 300;
        overflow: hidden;
        .message {
          position: relative;
          width: 280px;
          height: 83px;
          background: linear-gradient(31deg, #5a52e7, #f61660);
          padding: 15px 15px;
          box-sizing: border-box;
          line-height: 53px;
          .icon,
          span,
          .el-icon-edit-outline {
            vertical-align: middle;
            color: #fff;
          }
          .icon {
            position: absolute;
            top: 20px;
            right: 15px;
            font-size: 20px;
          }
          .avatarImg {
            vertical-align: middle;
            margin-right: 10px;
          }
        }
        .user-btn {
          padding: 5px 0;
          .el-menu {
            width: 100%;
            border-right: none;
            .el-menu-item {
              height: 40px;
              line-height: 40px;
              color: #000;
              i {
                font-size: 20px;
                margin-right: 10px;
                color: #000;
              }
            }
          }
        }
      }
    }

    .userInfo {
      float: right;
      // width: 180px;
      height: 100%;
      padding: 10px 0;
      box-sizing: border-box;
      .avatar {
        cursor: pointer;
        vertical-align: middle;
        transform: translateY(-2px);
        margin-right: 15px;
      }
      .login {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>
