<template>
  <div class="footer">
    <el-row :gutter="30">
      <el-col :span="6" class="col-box">
        <div class="grid-content bg-purple">{{ $t("footer.about") }}</div>
        <p v-html="$t('footer.aboutContent')">
        </p>
      </el-col>
      <el-col :span="5" class="col-box">
        <div class="grid-content bg-purple">{{ $t("footer.product") }}</div>
        <p v-html="$t('footer.productContent')"></p>
      </el-col>
      <el-col :span="5" class="col-box">
        <div class="grid-content bg-purple">{{ $t("footer.contact") }}</div>
        <p>
          Tel: 13922406564
          <br />Email: lianggangda@prometh.xyz
        </p>
      </el-col>
      <el-col :span="8" class="col-box">
        <div class="grid-content bg-purple">{{ $t("footer.concern") }}</div>
        <!-- <p>微信公众号</p>
        <img src="@/assets/Snipaste.png" />-->
        <el-row>
          <el-col :span="12">
            <p>{{ $t("footer.concernNumber") }}:</p>
            <img
              width="140"
              src="@/assets/wx.jpg"
            />
          </el-col>
          <el-col :span="12">
            <p>{{ $t("footer.concernContact") }}:</p>
            <img width="140" src="@/assets/weixin.jpg" />
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="case">
      ©2023 珠海普罗米修斯视觉技术有限公司 版权所有.
      <a href="https://beian.miit.gov.cn" target="_blank" class="recordLink">粤ICP备19034931号-1</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  height: 356px;
  min-width: 1380px;
  padding: 50px 250px;
  background-color: #282c33;
  box-sizing: border-box;
  .col-box {
    min-width: 180px;
  }
  .grid-content {
    font-size: 18px !important;
    color: #fff;
  }
  p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 23px;
  }
  .case {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
  }
  .recordLink:hover {
    color: #7dbbfc;
  }
}
</style>
