<template>
  <div id="app">
    <navBar />
    <sideBar />
    <keep-alive include="HelpCenter">
      <router-view :key="$route.fullPath" />
    </keep-alive>
    <footer-explain />
  </div>
</template>
<script>
import navBar from "@/components/navBar";
import sideBar from "@/components/sideBar";
import footerExplain from "@/components/footer-explain";
export default {
  components: {
    navBar,
    sideBar,
    footerExplain,
  },
  created() {
    // console.log(navigator.language);
    if (navigator.language==='zh-CN') {
      sessionStorage.setItem('language','zh')
      this.$i18n.locale = 'zh';
        document.querySelector('#companyName').innerHTML = '普罗米修斯视觉' 
    } else {
      sessionStorage.setItem('language','en')
      this.$i18n.locale = 'en';
      document.querySelector('#companyName').innerHTML = 'Prometheus'
    }
  }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
