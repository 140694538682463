import { Message } from "element-ui";

/**
 *
 * 模拟点击下载按钮
 */
export const clickA = (url, id) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = `${id}.mp4`;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  link.remove();
};
/**
 * 随机4个整数
 */
const numListFn = (list, numList) => {
  if (numList.length == 2) return
  const num = parseInt(list.length * Math.random());
  if (numList.indexOf(num) == -1) {
    numList.push(num);
  }
  numListFn(list, numList);
  return numList;
};
/**
 * 生成随机的4个列表
 */
export const randomList = (list, item) => {
  let numList = [];
  let refList = [];
  let newList = [];
  list.forEach((key) => {
    if (key.id == item.id) return;
    newList.push(key);
  });
  numList = numListFn(newList, numList);
  numList.forEach((item, index) => {
    refList.push(newList[item]);
    refList[index].collect = false;
  });

  return refList;
};
/**
 * 随机两位数
 */
const merchantList = (list, numList) => {
  if (numList.length == 2) return;
  const num = parseInt(list.length * Math.random());
  if (numList.indexOf(num) == -1) {
    numList.push(num);
  }
  merchantList(list, numList);
  return numList;
};
/**
 * 随机两个列表
 */
export const merchant = (list, item) => {
  let numList = [];
  let refList = [];
  let newList = [];
  list.forEach((key) => {
    if (key.id == item.id) return;
    newList.push(key);
  });
  numList = merchantList(newList, numList);
  numList.forEach((item, index) => {
    refList.push(newList[item]);
    refList[index].collect = false;
  });

  return refList;
};
/**
 * 判断中英文显示的方法
 */
export const toggle = (zh, en) => {
  if (sessionStorage.getItem('language') === "zh") {
    Message.success(zh);
  } else {
    Message.success(en);
  }
};
export const toggleMessage = (zh, en) => {
  if (sessionStorage.getItem('language') === "zh") {
    Message(zh);
  } else {
    Message(en);
  }
};
export const toggleError = (zh, en) => {
  if (sessionStorage.getItem('language') === "zh") {
    Message.error(zh);
  } else {
    Message.error(en);
  }
};
