import router from "@/router";
import store from "@/store";
//需要权限得路由。
const whiteList = [
  "/personal-center",
  "/personal-center/key-manage",
  "/personal-center/userinfo",
  "/personal-center/my-download",
  "/personal-center/my-collection",
  "/personal-center/order-form"
];
//前置路由守卫。
router.beforeEach((to, from, next) => {
  if (whiteList.includes(to.path)) {
    if (store.state.token) {
      next();
    } else {
      next({ path: "/" });
    }
  }
  next();
});
//后置路由守卫。
router.afterEach((to, from, next) => {
  window.scroll(0, 0);
});
