/**
 * 回到页面顶部动画。
 */
let flag = false;
let timeHandle = null;
export const animate = (obj, target) => {
  clearInterval(obj.timer);
  time();
  flag = false;
  obj.timer = setInterval(function () {
    let step = (target - window.pageYOffset) / 10;
    step = step > 0 ? Math.ceil(step) : Math.floor(step);
    if (window.pageYOffset == target || flag == true) {
      clearInterval(obj.timer);
    }
    window.scroll(0, window.pageYOffset + step);
  }, 15);
};
/**
 * 调用定时器
 */

function time() {
  if (timeHandle) {
    clearTimeout(timeHandle);
    timeHandle = null;
  }
  timeHandle = setTimeout(() => {
    flag = true;
  }, 2000);
}
