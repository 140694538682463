<template>
  <div class="personal-center">
    <div class="container">
      <el-menu
        class="el-menu-vertical-demo"
        background-color="#F8F8F8"
        text-color="#000"
        active-text-color="#ffd04b"
        router
        :default-active="$route.path"
      >
        <div class="title">
          <img src="@/assets/geren.png" class="el-avatar" />
          <p>{{ $store.state.phone }}</p>
        </div>
        <el-menu-item index="/personal-center/userinfo">
          <i class="iconfont icon-gerenzhongxin" />
          <span slot="title">{{ $t("login.message") }}</span>
        </el-menu-item>
        <el-menu-item index="/personal-center/my-download">
          <i class="iconfont icon-wodexiazai" />
          <span slot="title">{{ $t("login.download") }}</span>
        </el-menu-item>
        <el-menu-item index="/personal-center/my-collection">
          <i class="iconfont icon-wodeshoucang" />
          <span slot="title">{{ $t("login.collect") }}</span>
        </el-menu-item>
        <el-menu-item index="/personal-center/key-manage">
          <i class="iconfont icon-yuechi" />
          <span slot="title">{{ $t("login.key") }}</span>
        </el-menu-item>
        <!-- <el-menu-item index="/personal-center/order-form">
          <i class="iconfont icon-dingdan" />
          <span slot="title">{{$t("login.dingdan")}}</span>
        </el-menu-item> -->
      </el-menu>
      <div class="content">
        <keep-alive :include="['UserInfo', 'KeyManage']">
          <router-view :key="$route.fullPath" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PersonalCenter",
  components: {},
  data() {
    return {
      phone: "",
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.personal-center {
  background-image: linear-gradient(to right, #6e2934, #2d0f23);
  overflow: hidden;
  .container {
    position: relative;
    margin: 140px auto 60px;
    width: 1441px;
    height: 800px;
    background-color: #fff;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    .el-menu-vertical-demo {
      text-align: center;
      width: 249px;
      height: 100%;
      border-right: none;
      .title {
        padding: 40px 0;
        font-size: 16px;
        font-weight: bold;
        color: #1f1f1f;
        .el-avatar {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-bottom: 18px;
        }
        p {
          margin: 0;
        }
      }
      .el-menu-item {
        font-size: 16px;
        .iconfont {
          margin-right: 10px;
          font-size: 20px;
        }
      }
      .is-active {
        position: relative;
        background-color: #fff !important;
      }
      .is-active::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 5px;
        height: 30px;
        background-color: #ffc322;
      }
    }
    .content {
      width: 1192px;
      height: 100%;
      box-sizing: border-box;
      padding: 40px 110px 0 60px;
    }
  }
}
</style>
