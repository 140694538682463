import Vue from "vue";
import VueI18n from "vue-i18n";
import elementZH from "element-ui/lib/locale/lang/zh-CN";
import elementEN from "element-ui/lib/locale/lang/en";
import localeZH from "./zh.js";
import localeEN from "./en.js";
Vue.use(VueI18n);
//i18n国际化语言切换。
export default new VueI18n({
  locale: sessionStorage.getItem("language") || "zh",
  messages: {
    zh: {
      ...elementZH,
      ...localeZH,
    },
    en: {
      ...elementEN,
      ...localeEN,
    },
  },
});
