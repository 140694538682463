<template>
  <div class="sideBar">
    <transition>
      <div @click.stop="animation" ref="phone" id="phone">
        <i :class="language === 'zh-CN'? 'iconfont icon-dianhua': 'iconfont icon-youxiang'" />
        <span>{{language === 'zh-CN'? $t("sidebar.phone") : 'Email' }}</span>
      </div>
    </transition>
    <transition v-if="phoneShow" name="fade">
      <div class="phone" >{{language === 'zh-CN'? myPhone:myEmail}}</div>
    </transition>
    <el-popover placement="left" width="170" ref="popover" trigger="click">
      <div>
        <img width="150" src="@/assets/weixin.jpg" />
      </div>
      <div slot="reference" @click="elsePageControl" v-if="language === 'zh-CN'">
        <i class="iconfont icon-weixin" />
        <span>{{ $t("sidebar.weChat") }}</span>
      </div>
    </el-popover>
    <div @click="joinQQ"  v-if="language === 'zh-CN'">
      <i class="iconfont icon-qq-copy" />
      <span>{{ $t("sidebar.qq") }}</span>
    </div>
     <!-- <div @click="toEmail">
      <i class="iconfont icon-youxiang" />
      <span>Email</span>
    </div> -->
    <div @click="toFacebook" v-if="language !== 'zh-CN'">
      <i class="iconfont icon-facebook-fill" />
      <span>facebook</span>
    </div>
    <div @click="goBack">
      <i class="iconfont icon-back-top1_fill" />
      <span>{{ $t("sidebar.top") }}</span>
    </div>
    <a
      target="_blank"
      href="https://qm.qq.com/cgi-bin/qm/qr?k=YqcnKIkk0eUiqp7cRa3OFpmegfV26tku&jump_from=webapi"
      style="display: none"
      ref="joinqq"
    ></a>
    <a
      target="_blank"
      href="https://facebook.com/promethvision"
      style="display: none"
      ref="toFacebook"
    ></a>
  </div>
</template>
<script>
import { animate } from "@/utils/animate";
import { toggle, toggleMessage, toggleError } from "@/utils/common";
export default {
  name: "SideBar",
  data() {
    return {
      phoneShow: false,
      language: '',
      myPhone: '139-2240-6564',
      myEmail: 'dev@prometh.xyz'
    };
  },
  mounted() {
    this.language = navigator.language
    // if (this.language === 'zh-CN') {
      this.removeStyle();
    // }
  },
  methods: {
    //电话按钮缓动动画。
    animation() {
      this.phoneShow = !this.phoneShow;
      if (this.phoneShow) {
        if (this.language !== 'zh-CN') {
          this.copy(this.myEmail)
        }
        this.$refs.phone.classList.add("phoneAni");
      } else {
        this.$refs.phone.classList.remove("phoneAni");
      }
    },
    copy(item) {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.value = item;
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      toggle("复制成功", "Copied Successfully");
    },
    //点击任何地方删除样式。
    removeStyle() {
      document.body.addEventListener("click", (e) => {
        this.phoneShow = false;
        if (this.$refs.phone &&this.$refs.phone.classList) {
          this.$nextTick(() => {
            this.$refs.phone.classList.remove('phoneAni')
          });
        }
      });
    },
    //微信按钮点击事件。
    elsePageControl() {
      this.$refs.popover.doShow();
    },
    toFacebook() {
      this.$refs.toFacebook.click();
    },
    //返回顶部
    goBack() {
      animate(window, 0);
    },
    //加入qq群
    joinQQ() {
      this.$refs.joinqq.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.sideBar {
  position: fixed;
  top: 200px;
  right: 20px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .iconfont {
    font-size: 40px;
    color: #5a52e7;
  }
  div {
    width: 74px;
    height: 74px;
    background: #fff;
    cursor: pointer;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
    margin-bottom: 15px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 14px;
    }
  }
  .phoneAni {
    background-color: #5a52e7;
    color: #fff;
    border-radius: initial;
    .iconfont {
      color: #fff;
    }
  }
  .phone {
    width: 150px;
    position: absolute;
    top: 0;
    right: 74px;
    border-radius: initial;
    box-shadow: none;
    background-color: #5a52e7;
    color: #fff;
    transition: width 0.5s;
    font-size: 16px;
  }
  .fade-enter-active,
  .fade-leave-active {
    opacity: 1;
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
<style lang="scss">
.el-popover {
  min-width: 74px !important;
  border: none;
  padding: 8px 10px 6px !important;
}
</style>
