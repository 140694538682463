import Vue from "vue";
import VueRouter from "vue-router";
import HelpCenter from "./module/help-center";
import PersonalCenter from "./module/personal-center";
Vue.use(VueRouter);
//路由重定向报错。重写push
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/aboutUs/index.vue"),
  },
  {
    path: "/plug-in",
    name: "Plug-in",
    component: () => import("@/views/plug-in/index.vue"),
  },
  {
    path: "/detail/:id",
    name: "Detail",
    component: () => import("@/views/detail/index.vue"),
  },
  {
    path: "/merchants-page",
    name: "MerchantsPage",
    component: () => import("@/views/merchants-page/index.vue"),
  },
  {
    path: "/plug-in-en",
    name: "plugInEn",
    component: () => import("@/views/plug-in-en/index.vue"),
  },
  {
    path: "/halo-studio",
    name: "HaloStudio",
    component: () => import("@/views/halo-studio/index.vue"),
  },
  {
    path: "/objToMp4",
    name: "ObjToMp4",
    component: () => import("@/views/objToMp4/index.vue"),
  },
  HelpCenter,
  PersonalCenter,
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
