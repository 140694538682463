//处理时间函数
function timeFilters(dateStr) {
  let str = parseInt(dateStr.toString().padEnd(13, "0"));
  let dt = new Date(str);
  let yy = dt.getFullYear();
  let mm = (dt.getMonth() + 1).toString().padStart(2, "0");
  let dd = dt.getDate().toString().padStart(2, "0");
  return yy + "-" + mm + "-" + dd;
}
export const formaDate = (dateStr) => {
  if (dateStr) {
    return timeFilters(dateStr);
  } else {
    const dateStr = +new Date();
    return timeFilters(dateStr);
  }
};
//保留后两位
export const filterMoney = (money) => {
  if (money || money == 0) {
    return money.toFixed(2);
  }
};
//转换成分钟
export const filterTime = (time) => {
  if (time) {
    let m = parseInt(time / 60);
    let s = time % 60 < 10 ? "0" + (time % 60) : time % 60;
    return m + "." + s + " min";
  } else {
    return 0;
  }
};
// 提换标题符号
export const replaceTitle = (title) => {
  if (title) {
    return title.replace(/@/g,"\"")
  }
} 
