import request from "@/utils/request";
/**
 * 获取用户信息
 */
export const userInfo = () => {
  return request({
    url: "/users/info",
  });
};
/**
 * 修改用户信息
 */
export const editUser = (data) => {
  return request({
    url: "/users/info",
    method: "PUT",
    data,
  });
};
