import store from "@/store/index"
import axios from "axios"
import { Message } from "element-ui"
import { tokenExpired } from "@/api/login"
// const url = "https://otm.prometh.xyz/
const url = "https://cn-northwest-1-cps.prometh.xyz"
//添加基地址。
const request = axios.create({
  baseURL: url,
});
//请求拦截器。添加唯一id。和添加token。
request.interceptors.request.use(
  (config) => {
    config.headers["pm-deviceid"] = store.state.uuid
    if (store.state.token) {
      config.headers["pm-token"] = store.getters.token
    }
    return config
  },
  (error) => {
    return Promise.resolve(error);
  }
);
//响应拦截器。拦截不同报错情况处理。
request.interceptors.response.use(
  (config) => {
    return config
  },
  async (error) => {
    if (error.response.data && error.response.data.ret == "2") {
      store.commit("removeToken")
      Message.error("请重新登录")
      return Promise.resolve(error)
    }
    if (error.response.data && error.response.data.ret == "3") {
      let oldtoken = store.state.token
      let newtoken = await tokenExpired(oldtoken)
      store.commit("setToken", newtoken)
      Message.error("请重试")
      return Promise.resolve(error)
    }
    if (error.response.data && error.response.data.ret == "8") {
      Message.error("付费项目")
      return Promise.resolve(error)
    }
  }
);
export default request
